import React from "react";
import '../../index.css';


const NoPage = () => {


    return (
        <div className="nopage">
            <h2>404 Page Not Found</h2>
        </div>
    );
}

export default NoPage;